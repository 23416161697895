import React, { type ReactNode } from 'react';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createStateHook,
	createStore,
} from '@atlassian/react-sweet-state';
import { actions } from './actions.tsx';
import type { State } from './types.tsx';

const initialState: State = {
	activeMeatballTrigger: undefined,
};

const focusStore = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'plans-directory.focus-manager',
});

const FocusContainer = createContainer<State, typeof actions>(focusStore);

const useFocusData = createStateHook(focusStore);

const useFocusManager = createActionsHook(focusStore);

const FocusManagerProvider = ({ children }: { children: ReactNode }) => {
	return <FocusContainer>{children}</FocusContainer>;
};

export { useFocusManager, useFocusData, FocusManagerProvider };
