// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import type { Action } from '@atlassian/react-sweet-state';
import type { State } from './types.tsx';

const setActiveMeatballTrigger =
	(element?: HTMLElement | null): Action<State> =>
	({ setState }) => {
		setState({
			activeMeatballTrigger: element,
		});
	};

const focusMeatballTrigger =
	(): Action<State> =>
	({ getState, setState }) => {
		const activeMeatballTrigger = getState().activeMeatballTrigger;

		if (activeMeatballTrigger) {
			activeMeatballTrigger.focus();
			setState({ activeMeatballTrigger: undefined });
		}
	};

export const actions = {
	setActiveMeatballTrigger,
	focusMeatballTrigger,
};
