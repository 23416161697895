import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	delete: {
		id: 'plans.dashboards.main.content.cells.actions-cell.delete',
		defaultMessage: 'Delete',
		description: 'Label for a dropdown item to delete the plan',
	},
	moveToTrash: {
		id: 'plans.dashboards.main.content.cells.actions-cell.move-to-trash',
		defaultMessage: 'Move to trash',
		description: 'Label for a dropdown item to move the plan to trash',
	},
	duplicate: {
		id: 'plans.dashboards.main.content.cells.actions-cell.duplicate',
		defaultMessage: 'Duplicate',
		description: 'Label for a dropdown item to duplicate the plan',
	},
	archive: {
		id: 'plans.dashboards.main.content.cells.actions-cell.archive',
		defaultMessage: 'Archive',
		description: 'Label for a dropdown item to archive the plan',
	},
	untitledPlan: {
		id: 'plans.dashboards.main.content.cells.actions-cell.untitled-plan',
		defaultMessage: 'Untitled plan',
		description: 'Default title for a Jira plan',
	},
	moreActions: {
		id: 'plans.dashboards.main.content.cells.actions-cell.more-actions',
		defaultMessage: 'More actions for {name}',
		description: 'Label for a dropdown trigger to show more actions for a plan',
	},
	moreActionsTooltip: {
		id: 'plans.dashboards.main.content.cells.actions-cell.more-actions-tooltip',
		defaultMessage: 'More actions',
		description: 'Tooltip label for a dropdown trigger to show more actions for a plan',
	},
});
