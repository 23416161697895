import type { FlagConfiguration } from '@atlassian/jira-flags';
import { defineMessages } from '@atlassian/jira-intl';

const messages = defineMessages({
	title: {
		id: 'plans.dashboards.main.content.delete-modal.flags.error.title',
		defaultMessage: "We couldn't delete {name}",
		description:
			'Title of the flag shown in Plans Directory when the request for deleting a plan failed',
	},
	description: {
		id: 'plans.dashboards.main.content.delete-modal.flags.error.description',
		defaultMessage: 'There was an error, please try again.',
		description:
			'Message shown in the flag displayed in Plans Directory when the request for deleting a plan failed',
	},
});

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (name: string): FlagConfiguration => ({
	type: 'error',
	title: [messages.title, { name }],
	description: messages.description,
	messageId: `plans-main.ui.content.delete-modal.flags.error.${name}`,
	messageType: 'transactional',
});
