import React, { useRef } from 'react';
import { mergeRefs } from 'use-callback-ref';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import { Box, xcss } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';
import Tooltip from '@atlaskit/tooltip';
import {
	DUPLICATE,
	ARCHIVE,
	TRASH,
} from '@atlassian/jira-directory-base/src/model/operation-types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import type { EntityCellProps } from '../../../../common/types.tsx';
import { useFocusManager } from '../../../../controllers/focus-manager/index.tsx';
import messages from './messages.tsx';

const ActionsCell = ({ onOperationRequested, entity }: EntityCellProps) => {
	const isReadOnly = entity?.readOnly || false;
	const { formatMessage } = useIntl();

	const meatballTriggerRef = useRef<HTMLButtonElement>(null);

	const { setActiveMeatballTrigger } = fg('plan-timeline-a11y-rholloway')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useFocusManager()
		: { setActiveMeatballTrigger: undefined };

	const manageMeatballTrigger = () => {
		if (fg('plan-timeline-a11y-rholloway')) {
			setActiveMeatballTrigger?.(meatballTriggerRef.current);
		}
	};

	const handleRequestDuplicate = () => {
		manageMeatballTrigger();
		onOperationRequested(DUPLICATE, undefined);
	};

	const handleRequestArchive = () => {
		manageMeatballTrigger();
		onOperationRequested(ARCHIVE, undefined);
	};

	const handleRequestTrash = () => {
		manageMeatballTrigger();
		onOperationRequested(TRASH, undefined);
	};

	if (isReadOnly) {
		return null;
	}

	const dropdownMenu = (
		<DropdownMenu
			placement="bottom-end"
			trigger={({ triggerRef, ...props }) =>
				fg('plan-timeline-a11y-rholloway') ? (
					<Tooltip content={formatMessage(messages.moreActionsTooltip)}>
						<IconButton
							{...props}
							ref={mergeRefs([triggerRef, meatballTriggerRef])}
							appearance="subtle"
							label={formatMessage(messages.moreActions, {
								name: entity?.title || formatMessage(messages.untitledPlan),
							})}
							icon={MoreIcon}
						/>
					</Tooltip>
				) : (
					<Button
						{...props}
						ref={triggerRef}
						appearance="subtle"
						aria-label={
							entity?.title && fg('blu-6202-directories-a11y-fixes')
								? formatMessage(messages.moreActions, { name: entity?.title })
								: entity?.title || formatMessage(messages.untitledPlan)
						}
						iconBefore={<MoreIcon spacing="spacious" label="" />}
					/>
				)
			}
			shouldRenderToParent
		>
			<DropdownItemGroup>
				<DropdownItem onClick={handleRequestDuplicate}>
					{formatMessage(messages.duplicate)}
				</DropdownItem>
				<DropdownItem onClick={handleRequestTrash}>
					{formatMessage(messages.moveToTrash)}
				</DropdownItem>
				<DropdownItem onClick={handleRequestArchive}>
					{formatMessage(messages.archive)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);

	const testId = `plans-main.ui.content.cells.actions-cell.actions${entity ? `-${entity.id}` : ''}`;
	return (
		<Box xcss={containerStyles} testId={testId}>
			{dropdownMenu}
		</Box>
	);
};

export default ActionsCell;

const containerStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-end',
	paddingTop: 'space.050',
	paddingRight: 'space.025',
});
